import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  metaMaskWallet, coinbaseWallet, rainbowWallet, walletConnectWallet, rabbyWallet,
} from '@rainbow-me/rainbowkit/wallets';

import { http, createConfig } from 'wagmi';
import { berachainTestnetbArtio } from 'wagmi/chains';

export default createConfig({
  chains: [berachainTestnetbArtio],
  connectors: connectorsForWallets(
    [
      {
        groupName: 'Recommended',
        wallets: [rabbyWallet, metaMaskWallet, coinbaseWallet, rainbowWallet, walletConnectWallet],
      },
    ],
    {
      appName: 'BeraGemhuntersRainbowKit',
      projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID ?? '',
    },
  ),
  transports: {
    [berachainTestnetbArtio.id]: http('https://bartio.rpc.berachain.com/'), // TODO Alchemy for bArtio?
  },
});
